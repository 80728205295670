import { UseMutationResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { convertErrorToTi18nKey, IRequestError } from "../../api";
import bankId from "../../api/rest/auth/BankID";
import { Loading } from "../../components/design-system/Loading";
import {
  BankIdAuthDetails,
  NewSignatureRequest,
} from "../../types/models/auth";
import BankIdControls from "./BankIdControls";

const BankIdMobileSign = (props: {
  initMutation: UseMutationResult<
    BankIdAuthDetails,
    IRequestError,
    NewSignatureRequest,
    unknown
  >;
  onRetry: () => void;
  onSuccess: ({ signature }: { signature: string }) => void;
  onCancel?: () => void;
  onClickSameDevice: () => void;
}) => {
  const i18n = useTranslation();
  const [enableQueries, setEnableQueries] = useState(false);

  // Init -> inProgress
  useEffect(() => {
    if (props.initMutation.isSuccess) {
      setEnableQueries(true);
    }
  }, [props.initMutation.isSuccess]);

  // Two queries running whilst in progress
  const qrCodeQuery = bankId.useQRCodeQuery(
    props.initMutation.data?.qrStartToken || "",
    {
      refetchInterval: 3000,
      enabled: enableQueries,
    }
  );
  const collectQuery = bankId.useCollectSignQuery(
    props.initMutation.data?.orderRef || "",
    {
      onSuccess: (data) => {
        const { status: authStatus } = data;
        if (authStatus === "complete") {
          setEnableQueries(false);
          props.onSuccess(data);
        }
      },
      onError: () => setEnableQueries(false),
      retry: false,
      refetchInterval: 2000,
      enabled: enableQueries,
    }
  );

  const cancel = () => {
    setEnableQueries(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const mutations = [props.initMutation, qrCodeQuery, collectQuery];
  const failedMutation = mutations.find((x) => x.isError);
  const isSuccess = collectQuery.data?.status === "complete";
  const status: "init" | "success" | "inProgress" | "error" = failedMutation
    ? "error"
    : isSuccess
    ? "success"
    : props.initMutation.isSuccess
    ? "inProgress"
    : "init";
  const error =
    failedMutation && i18n.t(convertErrorToTi18nKey(failedMutation.error));

  const hintCode =
    collectQuery.data &&
    "hintCode" in collectQuery.data &&
    collectQuery.data.hintCode;

  const sameDevice = () => {
    setEnableQueries(false);
    props.onClickSameDevice();
  };

  if (status === "init" || qrCodeQuery.isLoading) {
    return <Loading />;
  }

  return (
    <BankIdControls
      title={i18n.t("sign.bankId")}
      status={status}
      qrCode={
        hintCode && hintCode === "userSign"
          ? undefined
          : qrCodeQuery.data?.qrCode
      }
      cancel={cancel}
      sameDevice={sameDevice}
      error={error}
      retry={props.onRetry}
    />
  );
};

export default BankIdMobileSign;
