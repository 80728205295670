import { isValid, parse } from "date-fns";

const emailRegex = /\S+@\S+\.\S+/;

function calculateControlDigitForSwedishPersonalNumber(personalNumber: string) {
  const personalNumberWithoutControlDigit = personalNumber
    .substring(2, 11)
    .split("");

  const multipliedValues = personalNumberWithoutControlDigit.map(
    (digit, index) => {
      const nonZeroIndex = index + 1;
      const multiplier = nonZeroIndex % 2 === 0 ? 1 : 2;
      const multipliedValue = parseInt(digit, 10) * multiplier;
      if (multipliedValue > 9) {
        const firstDigit = Math.floor(multipliedValue / 10);
        const secondDigit = multipliedValue % 10;
        return firstDigit + secondDigit;
      }

      return multipliedValue;
    }
  );
  const sum = multipliedValues.reduce((acc, value) => acc + value, 0);

  const mostRightDigitOfSum = sum % 10;

  const calculatedControlDigit = 10 - mostRightDigitOfSum;

  if (calculatedControlDigit === 10) {
    return 0;
  }
  return calculatedControlDigit;
}

const validateSwedishPersonalNumber = (input: string) => {
  const is12DigitsValueWithHyphen = /^\d{8}-\d{4}$/.test(input);

  const formatedInput = is12DigitsValueWithHyphen
    ? input.replace("-", "")
    : input;

  const is12DigitsValue = /^\d{12}$/.test(formatedInput);

  if (!is12DigitsValue) {
    return "swedishPersonalNumber.format.invalid";
  }

  const dateString = formatedInput.substring(0, 8);
  const date = parse(dateString, "yyyyMMdd", new Date());

  if (!isValid(date) || date >= new Date()) {
    return "swedishPersonalNumber.date.invalid";
  }

  const controlDigit = parseInt(formatedInput[11] as string, 10);
  const calculatedDigit =
    calculateControlDigitForSwedishPersonalNumber(formatedInput);
  if (controlDigit !== calculatedDigit) {
    return "swedishPersonalNumber.controlDigit.invalid";
  }
  return null;
};

export { emailRegex, validateSwedishPersonalNumber };
