import "./index.css";

import { TagsInput as Input } from "react-tag-input-component";

type TagsInputProps = {
  value: string[];
  onChange: (value: string[]) => void;
  name: string;
  placeholder?: string;
  validate?: (tag: string, existingTags: string[]) => boolean;
};

const TagsInput = ({
  value,
  onChange,
  name,
  placeholder,
  validate,
}: TagsInputProps) => {
  return (
    <Input
      value={value}
      onChange={onChange}
      name={name}
      placeHolder={placeholder}
      separators={["Enter", ",", " "]}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
        // Add the current item when the click away
        if (e.target.value.trim()) {
          if (!validate || validate(e.target.value.trim(), value)) {
            onChange([...value, e.target.value.trim()]);
          }
          e.target.value = "";
        }
      }}
      beforeAddValidate={validate}
      classNames={{
        input: "tw-border-none tw-outline-none focus:tw-ring-0 tw-w-full",
        tag: "tw-text-xs tw-rounded !tw-p-1 !tw-bg-sky-100 tw-text-sky-900",
      }}
    />
  );
};

export { TagsInput };
