import { clsxm } from "../../../utils/tailwind";
import { Column } from "./Column";

type TableProps = React.HTMLAttributes<HTMLSpanElement> & {
  columns: {
    name: string;
    title: string;
    className?: string;
    colspan?: number;
    sortable?: boolean;
  }[];
  sortBy: string;
  setSortBy: (value: string) => void;
  children: React.ReactNode;
  fixed?: boolean;
};

const Table = ({ children, columns, sortBy, setSortBy, fixed }: TableProps) => {
  return (
    <div className="tw-relative tw-overflow-x-auto">
      <table className={clsxm("tw-w-full", { "tw-table-fixed": fixed })}>
        <thead className="tw-text-left">
          <tr className="tw-border-b">
            {columns.map(({ name, title, sortable, colspan, className }) => (
              <th
                scope="col"
                className={clsxm("tw-px-4 tw-py-2.5 tw-font-normal", className)}
                key={name}
                colSpan={colspan || 1}
              >
                {name && (
                  <Column
                    title={title}
                    sortDesc={sortBy === `${name}-desc`}
                    sortAsc={sortBy === `${name}-asc`}
                    onClick={() => {
                      setSortBy(
                        sortBy === `${name}-asc`
                          ? `${name}-desc`
                          : `${name}-asc`
                      );
                    }}
                    sortable={sortable !== undefined ? sortable : true}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export { Table };
export type { TableProps };
