import { ArrowSquareOut } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { setLocale } from "../../i18n";
import { locales } from "../../i18n/locale";
import { LogoIcon } from "../design-system/icons";

const PublicPageLayout = ({
  children,
  className = "tw-flex tw-w-full tw-flex-1 tw-items-center max-md:tw-px-4",
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const { i18n } = useTranslation();
  const newLocale = i18n.language === "en-US" ? "sv-SE" : "en-US";

  return (
    <div className="tw-mx-auto tw-flex tw-h-screen tw-w-full tw-max-w-7xl tw-flex-col tw-items-center">
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4 max-md:tw-px-6">
        <LogoIcon />
        <div className="tw-flex tw-gap-4">
          <button
            onClick={() => setLocale(newLocale)}
            className="tw-text-primary"
            type="button"
          >
            {locales[newLocale]}
          </button>
          <Link
            to="https://www.kvanta.com"
            className="tw-flex tw-gap-2 tw-text-primary max-md:tw-hidden"
          >
            Kvanta.com <ArrowSquareOut />
          </Link>
        </div>
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

export { PublicPageLayout };
