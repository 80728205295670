import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../../components/design-system/FormGroup";
import { Input } from "../../../../components/design-system/Input";
import { ShareType } from "../../../../types/models/shares";

const formId = "create-share-class-form";

type CreateShareClassDialogProps = {
  onClose: () => void;
  onSubmit: (data: ShareType) => void;
  shareTypes: ShareType[];
};

const CreateShareClassDialog = ({
  onClose,
  onSubmit,
  shareTypes,
}: CreateShareClassDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ShareType>({ mode: "onSubmit" });
  const minVote = Math.min(...shareTypes.map((type) => type.voteValue));
  const maxVote = Math.max(...shareTypes.map((type) => type.voteValue));

  return (
    <Dialog
      isOpen
      title={i18n.t("onboarding.action.addShareClass")}
      onClose={() => onClose()}
      isLoading={false}
      actions={
        <>
          <Button
            variant="solid"
            color="primary"
            isLoading={false}
            form={formId}
            type="submit"
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => {
            onSubmit(data);
          })(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="name">{i18n.t("label.name")}</FormLabel>
          <Input
            id="name"
            {...register("name", {
              required: i18n.t("error.validation.required"),
              maxLength: {
                value: 30,
                message: i18n.t("error.validation.maxCharacters", { max: 30 }),
              },
              validate: (value) => {
                if (
                  shareTypes.some(
                    (other) =>
                      other.name.trim().toLowerCase() ===
                      value.trim().toLowerCase()
                  )
                ) {
                  return i18n.t("error.validation.unique");
                }

                return true;
              },
            })}
          />
          <FormError>{errors.name?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="voteValue">
            {i18n.t("label.numberOfVotesPerShare")}
          </FormLabel>
          <Input
            id="voteValue"
            {...register("voteValue", {
              required: i18n.t("error.validation.required"),
              valueAsNumber: true,
              validate: (val) => {
                if (Number.isNaN(val) || val <= 0) {
                  return i18n.t("error.validation.range.greaterThanOrEqual", {
                    min: 0,
                  });
                }

                const range = { min: maxVote / 10, max: minVote * 10 };
                if (val < range.min || val > range.max) {
                  return i18n.t("error.validation.range.votes", range);
                }

                return true;
              },
            })}
            step={0.01}
            type="number"
          />
          <FormError>{errors.voteValue?.message}</FormError>
        </FormGroup>
      </form>
    </Dialog>
  );
};

export { CreateShareClassDialog };
