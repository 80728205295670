import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Description } from "../../../components/design-system/Description";
import { EntityItem } from "../../../components/design-system/EntityItem";
import { SortIcon } from "../../../components/design-system/icons";
import { Menu } from "../../../components/design-system/Menu";
import { Table } from "../../../components/design-system/Table";
import { NoData } from "../../../components/NoData";
import { SelectionMenuItem } from "../../../components/SelectionMenuItem";
import { ShareHoldersRow } from "../../../components/ShareHolders/ShareHoldersRow";
import { formatNumber, formatPercentage } from "../../../utils/format";
import { clsxm } from "../../../utils/tailwind";
import { ShareHolderWithDilution } from "../ShareHolders.utils";

const SortBySchema = z.enum([
  "name-asc",
  "name-desc",
  "shares-asc",
  "shares-desc",
  "votes-asc",
  "votes-desc",
]);

type SortBy = z.infer<typeof SortBySchema>;

const sortHolders = (
  holders: ShareHolderWithDilution[],
  key: SortBy,
  displayDiluted: boolean
) => {
  const [sortBy, sortOrder] = key.split("-");
  const isAscending = sortOrder === "asc";
  let compareFunction = (
    a: ShareHolderWithDilution,
    b: ShareHolderWithDilution
  ) => (isAscending ? 1 : -1) * a.entity.name.localeCompare(b.entity.name);

  if (sortBy === "shares") {
    if (displayDiluted) {
      compareFunction = (a, b) =>
        isAscending
          ? (a.dilutedShares || 0) - (b.dilutedShares || 0)
          : (b.dilutedShares || 0) - (a.dilutedShares || 0);
    } else {
      compareFunction = (a, b) =>
        isAscending
          ? a.totalShares - b.totalShares
          : b.totalShares - a.totalShares;
    }
  } else if (sortBy === "votes") {
    if (displayDiluted) {
      compareFunction = (a, b) =>
        isAscending
          ? (a.dilutedVotes || 0) - (b.dilutedVotes || 0)
          : (b.dilutedVotes || 0) - (a.dilutedVotes || 0);
    } else {
      compareFunction = (a, b) =>
        isAscending ? a.totalVotes - b.totalVotes : b.totalVotes - a.totalVotes;
    }
  }

  return holders.sort((a, b) => {
    if (!a || !b) {
      return 0;
    }
    return compareFunction(a, b);
  });
};

type ShareholdersTableProps = {
  displayDiluted: boolean;
  dilutedTotals?: {
    totalShares: number;
    totalVotes: number;
  };
  searchValue?: string;
  totalShares: number;
  totalVotes: number;
  sortBy: SortBy;
  setSortBy: (value: SortBy) => void;
  shareholders: ShareHolderWithDilution[];
};

const ShareholdersTable = ({
  displayDiluted,
  dilutedTotals,
  searchValue,
  totalShares,
  totalVotes,
  sortBy,
  setSortBy,
  shareholders,
}: ShareholdersTableProps) => {
  const i18n = useTranslation();
  const [displayType, setDisplayType] = useState<"shares" | "votes">("shares");
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const menuItems: Record<SortBy, string> = {
    "shares-desc": i18n.t("sortBy.shares.desc"),
    "shares-asc": i18n.t("sortBy.shares.asc"),
    "name-asc": i18n.t("sortBy.name.asc"),
    "name-desc": i18n.t("sortBy.name.desc"),
    "votes-desc": i18n.t("sortBy.votes.desc"),
    "votes-asc": i18n.t("sortBy.votes.asc"),
  };

  const getCurrentSortLabel = (): string => {
    // Assuming menuItems is already defined and populated as shown previously
    return menuItems[sortBy] || i18n.t("label.sortBy"); // Fallback to a default label if needed
  };

  const toggleRow = (id: string) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const sortedHolders = useMemo(() => {
    return sortHolders(shareholders, sortBy, displayDiluted);
  }, [shareholders, sortBy]);

  return (
    <>
      <div
        id="mobile-view"
        className="tw-flex tw-flex-col tw-gap-2 tw-pb-10 md:tw-hidden"
      >
        <div className="tw-flex tw-justify-between">
          <Menu>
            <Menu.Button
              isDropdown={false}
              className="tw-border-none tw-text-sm max-md:tw-w-full"
            >
              <div className="tw-flex tw-items-center tw-gap-1 tw-text-secondary">
                {getCurrentSortLabel()}
                <SortIcon size={24} />
              </div>
            </Menu.Button>
            <Menu.Items align="bottomLeft">
              {Object.entries(menuItems).map(([key, label]) => (
                <Menu.Item
                  key={key}
                  as="button"
                  onClick={() => {
                    setSortBy(SortBySchema.parse(key));
                  }}
                >
                  <SelectionMenuItem checked={sortBy === key}>
                    {label}
                  </SelectionMenuItem>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
          <Menu>
            <Menu.Button className="tw-border-none tw-text-sm tw-text-secondary max-md:tw-w-full">
              {displayType === "shares"
                ? displayDiluted
                  ? i18n.t("label.diluted.shares")
                  : i18n.t("label.shares")
                : displayDiluted
                ? i18n.t("label.diluted.votes")
                : i18n.t("label.votes")}
            </Menu.Button>
            <Menu.Items align="bottomRight">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={clsxm(
                      "tw-block tw-w-full tw-px-4 tw-py-2 tw-text-left tw-text-sm",
                      active ? "tw-bg-gray-100" : "tw-text-gray-700"
                    )}
                    onClick={() => setDisplayType("shares")}
                  >
                    {displayDiluted
                      ? i18n.t("label.diluted.shares")
                      : i18n.t("label.shares")}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={clsxm(
                      "tw-block tw-w-full tw-px-4 tw-py-2 tw-text-left tw-text-sm",
                      active ? "tw-bg-gray-100" : "tw-text-gray-700"
                    )}
                    onClick={() => setDisplayType("votes")}
                  >
                    {displayDiluted
                      ? i18n.t("label.diluted.votes")
                      : i18n.t("label.votes")}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
        {sortedHolders.map((holder) => {
          if (!holder) {
            return null;
          }

          const percentageValue =
            displayType === "shares"
              ? displayDiluted && dilutedTotals
                ? formatPercentage(
                    holder.dilutedShares! / dilutedTotals.totalShares
                  )
                : formatPercentage(holder.totalShares / totalShares)
              : displayDiluted && dilutedTotals
              ? formatPercentage(
                  holder.dilutedVotes! / dilutedTotals.totalVotes
                )
              : formatPercentage(holder.totalVotes / totalVotes);

          const numberValue =
            displayType === "shares"
              ? displayDiluted
                ? formatNumber(holder.dilutedShares!)
                : formatNumber(holder.totalShares)
              : displayDiluted
              ? formatNumber(holder.dilutedVotes!)
              : formatNumber(holder.totalVotes);

          return holder ? (
            <button
              key={holder.holder.id}
              type="button"
              className="tw-relative tw-rounded tw-p-4 tw-text-left"
              style={{
                boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
              }}
              onClick={() => toggleRow(holder.holder.id)}
            >
              <div className="tw-flex tw-justify-between tw-gap-4">
                <EntityItem
                  value={holder.entity}
                  showId
                  hasFlag
                  displayIcon={false}
                />
                <Description
                  align="right"
                  titleSize="base"
                  titleWeight="medium"
                  title={percentageValue}
                  description={numberValue}
                />
              </div>
              <div
                className={clsxm(
                  "tw-flex tw-flex-col tw-gap-4 tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out",
                  {
                    "tw-max-h-96 tw-opacity-100":
                      expandedRows[holder.holder.id],
                    "tw-max-h-0 tw-opacity-0": !expandedRows[holder.holder.id],
                  }
                )}
              >
                <div className="tw-pt-2.5" id="drop">
                  <Description
                    title={
                      <p className="tw-text-sm tw-text-secondary">
                        {displayType === "shares"
                          ? i18n.t("label.shares")
                          : i18n.t("label.votes")}
                      </p>
                    }
                    description={
                      <div className="tw-flex tw-items-end tw-gap-1">
                        <p className="tw-text-base tw-font-medium tw-text-neutral-800">
                          {displayType === "shares"
                            ? formatPercentage(holder.totalShares / totalShares)
                            : formatPercentage(holder.totalVotes / totalVotes)}
                        </p>
                        <p className="tw-h-[21px]">
                          {displayType === "shares"
                            ? formatNumber(holder.totalShares)
                            : formatNumber(holder.totalVotes)}
                        </p>
                      </div>
                    }
                  />
                </div>
                {displayDiluted && (
                  <div className="tw-pt-2.5" id="drop">
                    <Description
                      title={
                        <p className="tw-text-sm tw-text-secondary">
                          {displayType === "shares"
                            ? i18n.t("label.diluted.shares")
                            : i18n.t("label.diluted.votes")}
                        </p>
                      }
                      description={
                        <div className="tw-flex tw-items-end tw-gap-1">
                          <p className="tw-text-base tw-font-medium tw-text-neutral-800">
                            {dilutedTotals &&
                              (displayType === "shares"
                                ? formatPercentage(
                                    holder.dilutedShares! /
                                      dilutedTotals.totalShares
                                  )
                                : formatPercentage(
                                    holder.dilutedVotes! /
                                      dilutedTotals.totalVotes
                                  ))}
                          </p>
                          <p className="tw-h-[21px]">
                            {displayType === "shares"
                              ? formatNumber(holder.dilutedShares!)
                              : formatNumber(holder.dilutedVotes!)}
                          </p>
                        </div>
                      }
                    />
                  </div>
                )}
                <div className="tw-flex tw-flex-col tw-gap-1">
                  {Object.entries(holder.shareTypes).map(([type, shares]) => {
                    return (
                      <div
                        className="tw-flex tw-justify-between tw-text-sm"
                        key={type}
                      >
                        <p className="tw-font-medium">{type}</p>
                        <p className="tw-font-medium">{formatNumber(shares)}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="tw-absolute tw-bottom-0 tw-left-0 tw-h-1 tw-rounded-bl"
                style={{
                  backgroundColor: "#9E79ED",
                  width: `${
                    (displayDiluted && dilutedTotals
                      ? displayType === "shares"
                        ? holder.dilutedShares! / dilutedTotals.totalShares
                        : holder.dilutedVotes! / dilutedTotals.totalVotes
                      : displayType === "shares"
                      ? holder.totalShares / totalShares
                      : holder.totalVotes / totalVotes) * 100
                  }%`,
                }}
              />
            </button>
          ) : null;
        })}
        <div className="tw-p-4">
          <Description
            align="right"
            title={formatPercentage(1)}
            titleSize="base"
            titleWeight="medium"
            description={formatNumber(
              displayType === "shares"
                ? displayDiluted && dilutedTotals
                  ? dilutedTotals.totalShares
                  : totalShares
                : displayDiluted && dilutedTotals
                ? dilutedTotals.totalVotes
                : totalVotes
            )}
          />
        </div>
      </div>
      <div className="max-md:tw-hidden">
        {sortedHolders.length > 0 ? (
          <Table
            fixed={false}
            columns={
              displayDiluted
                ? [
                    {
                      name: "name",
                      title: i18n.t("label.shareholder"),
                    },
                    {
                      name: "shares",
                      title: i18n.t("label.shares"),
                    },
                    {
                      name: "sharesDiluted",
                      title: i18n.t("label.diluted.shares"),
                    },
                    {
                      name: "votes",
                      title: i18n.t("label.votes"),
                    },
                    {
                      name: "votesDiluted",
                      title: i18n.t("label.diluted.votes"),
                    },
                    {
                      name: "shareClass",
                      title: i18n.t("label.shareClass"),
                      sortable: false,
                      colspan: 1,
                      className: "tw-max-w-[200px]",
                    },
                  ]
                : [
                    {
                      name: "name",
                      title: i18n.t("label.shareholder"),
                    },
                    {
                      name: "shares",
                      title: i18n.t("label.shares"),
                    },
                    {
                      name: "votes",
                      title: i18n.t("label.votes"),
                    },
                    {
                      name: "shareClass",
                      title: i18n.t("label.shareClass"),
                      sortable: false,
                      colspan: 2,
                      className: "tw-max-w-[200px]",
                    },
                  ]
            }
            sortBy={sortBy}
            setSortBy={(value: string) => setSortBy(value as SortBy)}
          >
            {sortedHolders.map((holder) =>
              holder ? (
                <ShareHoldersRow
                  key={holder.holder.id}
                  holder={holder}
                  totalShares={totalShares}
                  totalVotes={totalVotes}
                  dilutedTotals={
                    dilutedTotals || { totalShares: 0, totalVotes: 0 }
                  }
                />
              ) : null
            )}
            {!searchValue && (
              <tr>
                <td />
                <td className="tw-px-6 tw-py-4">
                  <Description
                    title={`${formatPercentage(100 / 100)}`}
                    description={formatNumber(totalShares)}
                  />
                </td>
                {displayDiluted && (
                  <td className="tw-px-6 tw-py-4">
                    <Description
                      title={`${formatPercentage(100 / 100)}`}
                      description={
                        dilutedTotals && formatNumber(dilutedTotals.totalShares)
                      }
                    />
                  </td>
                )}
                <td className="tw-px-6 tw-py-4">
                  <Description
                    title={`${formatPercentage(100 / 100)}`}
                    description={formatNumber(totalVotes)}
                  />
                </td>
                {displayDiluted && (
                  <td className="tw-px-6 tw-py-4">
                    <Description
                      title={`${formatPercentage(100 / 100)}`}
                      description={
                        dilutedTotals && formatNumber(dilutedTotals.totalVotes)
                      }
                    />
                  </td>
                )}
                <td colSpan={displayDiluted ? 1 : 2} />
              </tr>
            )}
          </Table>
        ) : (
          <div className="tw-rounded tw-border">
            <NoData />
          </div>
        )}
      </div>
      <div className="tw-hidden tw-grid-cols-9 tw-gap-4 tw-px-4 tw-pt-2 print:tw-mt-8 print:tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3">
        <div className="tw-col-span-3 tw-font-medium md:tw-col-span-2 lg:tw-col-span-1">
          {i18n.t("shares.totalAllHolders")}
        </div>
        <div className="tw-col-span-2">
          <Description
            title={i18n.t("label.shares.count", {
              count: totalShares,
            })}
            description={`${formatPercentage(100 / 100)}`}
          />
        </div>
        <div className="tw-col-span-2">
          <Description
            title={i18n.t("label.votes.count", {
              count: totalVotes,
            })}
            description={`${formatPercentage(100 / 100)}`}
          />
        </div>
      </div>
    </>
  );
};

export type { SortBy };
export { ShareholdersTable };
