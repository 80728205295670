import { FilePdf } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { convertErrorToTi18nKey } from "../../api";
import {
  useLedgerQuery,
  useShareblocksQuery,
  useShareholdersQuery,
  useShareTypesQuery,
} from "../../api/blockchain/company";
import { useParentEventsQuery } from "../../api/blockchain/events";
import { useOptionsProgramQuery } from "../../api/blockchain/options";
import { shareholdersDocumentDownload } from "../../api/rest/company";
import { useEntitiesQuery } from "../../api/rest/entities";
import { BottomBar } from "../../components/design-system/BottomBar";
import { Chip } from "../../components/design-system/Chip";
import {
  ExcelIcon,
  InfoIcon,
  SearchIcon,
} from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { Menu } from "../../components/design-system/Menu";
import { Toggle } from "../../components/design-system/Toggle";
import { getEntityWithFallback } from "../../components/EventList/EventsTable/EventsTable.utils";
import { NoData } from "../../components/NoData";
import { PageWrapper } from "../../components/PageWrapper";
import { SelectVersion } from "../../components/SelectVersion";
import { getTotalSharesByType } from "../../components/ShareBlocks/ShareBlocks.utils";
import { SharesPrint } from "../../components/SharesPrint";
import { ShareView } from "../../components/ShareView";
import { getLocale } from "../../i18n";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { Entity } from "../../types/models/entities";
import { OptionsProgram } from "../../types/models/options";
import { Shareholder } from "../../types/models/shares";
import { getFormattedDate, isGreaterLedgerVersion } from "../../utils/date";
import { downloadBlob } from "../../utils/download";
import { makeAndDownloadExcel } from "../../utils/excel";
import { generateShareholderData } from "../../utils/excel-utils";
import * as monitoring from "../../utils/monitoring";
import { clsxm } from "../../utils/tailwind";
import {
  getDilutionTotals,
  getShareHoldersWithDilution,
  ShareHolderWithDilution,
} from "./ShareHolders.utils";
import {
  ShareholdersTable,
  SortBy,
} from "./ShareholdersTable/ShareholdersTable";
import type { LedgerVersionDetails } from "./useLedgerVersions";

type Props = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
};

export const CLASS_CELL_GAP = "tw-gap-6";

const shareHolderMatchSearchValue = (
  shareHolder: Shareholder & { entity: Entity },
  searchValue?: string
) => {
  if (!searchValue || searchValue.length < 1) {
    return true;
  }
  const { entity } = shareHolder;

  return (
    entity.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    entity.refId.toLowerCase().includes(searchValue.toLowerCase()) ||
    Object.keys(shareHolder.shareTypes).some((share_type) =>
      share_type.toLowerCase().includes(searchValue.toLowerCase())
    )
  );
};

const OptionsProgramFilter = ({
  optionsPrograms,
  selectedOptionsPrograms,
  setSelectedOptionsPrograms,
  className,
}: {
  optionsPrograms: OptionsProgram[];
  selectedOptionsPrograms: string[];
  setSelectedOptionsPrograms: Dispatch<SetStateAction<string[]>>;
  className?: string;
}) => (
  <div className={clsxm("tw-flex tw-flex-wrap tw-gap-2", className)}>
    {optionsPrograms.map((p) => {
      return (
        <Chip
          key={p.title}
          isActive={selectedOptionsPrograms.includes(p.id!)}
          onClick={() =>
            setSelectedOptionsPrograms((prevState) => {
              if (prevState.includes(p.id!)) {
                return prevState.filter((item) => item !== p.id);
              }
              return [...prevState, p.id!];
            })
          }
        >
          {p.title}
        </Chip>
      );
    })}
  </div>
);

const Shareholders = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  setSelectedVersion,
}: Props) => {
  const i18n = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>("shares-desc");
  const [displayDiluted, setDisplayDiluted] = useState(false);
  const [selectedOptionsProgramIds, setSelectedOptionsProgramsIds] = useState<
    string[]
  >([]);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const downloadShareholders = async () => {
    setIsDownloadLoading(true);
    const response = await shareholdersDocumentDownload(
      currentCompany.orgNumber,
      selectedVersion!.formatedValue,
      displayDiluted ? selectedOptionsProgramIds : []
    );
    if (response.status === 200) {
      const blob = await response.blob();
      downloadBlob(
        blob,
        `${i18n.t("label.shareholders")}_${currentCompany.name}_${
          currentCompany.orgNumber
        }_${selectedVersion!.formatedValue}.pdf`
      );
    } else {
      console.error(response);
      monitoring.captureException(
        new Error("Error downloading shareholders document"),
        {
          extra: {
            status: response.status,
            text: response.statusText,
            company: currentCompany.orgNumber,
            version: selectedVersion?.formatedValue,
          },
        }
      );
    }
    setIsDownloadLoading(false);
  };

  const ledgerQuery = useLedgerQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );

  const shareHoldersQuery = useShareholdersQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );

  const shareBlocksQuery = useShareblocksQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );
  const shareBlocks = shareBlocksQuery.data || [];

  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");

  const shareTypesByName = useMemo<Record<string, number>>(() => {
    if (!shareTypesQuery.isSuccess || !shareTypesQuery.data) {
      return {};
    }
    return shareTypesQuery.data.reduce(
      (result, shareType) => ({
        ...result,
        [shareType.name]: shareType.voteValue,
      }),
      {}
    );
  }, [shareTypesQuery.data, shareTypesQuery.isSuccess]);

  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap: Record<string, Entity> = Object.fromEntries(
    entitiesData.map((e) => [e.id, e])
  );

  const optionsProgramsQuery = useOptionsProgramQuery(currentCompany.orgNumber);
  const optionsPrograms = optionsProgramsQuery.data || [];
  const selectedOptionPrograms = optionsPrograms.filter((x) =>
    selectedOptionsProgramIds.includes(x.id!)
  );

  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = selectedVersion
    ? eventsQuery.data?.data.filter(
        (e) => !isGreaterLedgerVersion(e.date, selectedVersion.formatedValue)
      )
    : eventsQuery.data?.data || [];

  const {
    mappedShareHolders,
    hasSplit,
  }: {
    mappedShareHolders: ShareHolderWithDilution[] | undefined;
    hasSplit: boolean;
  } = useMemo(() => {
    if (
      !shareHoldersQuery.isSuccess ||
      !entitiesQuery.isSuccess ||
      !shareTypesQuery.isSuccess
    ) {
      return { mappedShareHolders: undefined, hasSplit: false };
    }

    const shareHolders = (shareHoldersQuery.data?.shareholders || [])
      .map((shareholder) => {
        const entity = getEntityWithFallback(entitiesMap, shareholder.holder);
        if (!("type" in entity)) {
          console.error("No entity fallback found");
          monitoring.captureException(
            new TypeError("Shareholder missing in entities"),
            { contexts: { entitiesMap, holder: shareholder.holder } }
          );

          return null;
        }
        return { ...shareholder, entity };
      })
      .filter((x) => x !== null);

    const shareHoldersFiltered = shareHolders.filter((x) =>
      shareHolderMatchSearchValue(x, searchValue)
    );

    if (displayDiluted) {
      const { shareHoldersWithDilution, hasSplit: split } =
        getShareHoldersWithDilution(
          shareHoldersFiltered,
          selectedOptionPrograms,
          shareTypesByName,
          entitiesMap,
          events || []
        );
      return {
        mappedShareHolders: shareHoldersWithDilution,
        hasSplit: split,
      };
    }

    return {
      mappedShareHolders: shareHoldersFiltered,
      hasSplit: false,
    };
  }, [
    entitiesQuery.isSuccess,
    entitiesMap,
    searchValue,
    shareHoldersQuery.isSuccess,
    shareHoldersQuery.data,
    shareTypesQuery.isSuccess,
    shareTypesByName,
    events,
    displayDiluted,
    selectedOptionPrograms,
  ]);

  const totalSharesByType = getTotalSharesByType(shareBlocks);

  const totalVotes = shareHoldersQuery.data?.totalVotes || 0;
  const totalShares = shareHoldersQuery.data?.totalShares || 0;
  const dilutedTotals = getDilutionTotals(
    totalShares,
    totalVotes,
    shareTypesByName,
    selectedOptionPrograms
  );

  const isLoading =
    shareHoldersQuery.isLoading ||
    entitiesQuery.isLoading ||
    shareTypesQuery.isLoading ||
    !Array.isArray(mappedShareHolders);
  const isSuccess =
    shareHoldersQuery.isSuccess &&
    entitiesQuery.isSuccess &&
    shareTypesQuery.isSuccess &&
    Array.isArray(mappedShareHolders);
  const errorCode =
    (shareHoldersQuery.error &&
      convertErrorToTi18nKey(shareHoldersQuery.error)) ||
    (entitiesQuery.error && convertErrorToTi18nKey(entitiesQuery.error)) ||
    (shareTypesQuery.error && convertErrorToTi18nKey(shareTypesQuery.error));

  const versionLabel = selectedVersion
    ? `${getFormattedDate(selectedVersion.date)} ${i18n.t("label.version")} ${
        selectedVersion.version
      }`
    : "";

  const isSortedHoldersEmpty = (mappedShareHolders || []).length === 0;

  const approvedLedgerVersions = ledgerVersions.filter(
    (version) => version.isApproved
  );

  const toggleDilutedView = () => {
    setSelectedOptionsProgramsIds(
      optionsPrograms
        .filter((x) => new Date(x.strikeEndDate) >= new Date())
        .map((x) => x.id!)
    );
    setDisplayDiluted(!displayDiluted);
  };

  return (
    <>
      <SharesPrint
        currentCompany={currentCompany}
        numberOfShareholders={shareHoldersQuery.data?.shareholders.length || 0}
        ledgerData={ledgerQuery.data}
        currentVersion={selectedVersion}
        approvedLedgerVersions={approvedLedgerVersions}
      />
      <PageWrapper
        className="tw-py-4 max-md:tw-pb-10"
        data-testid="shares-layout"
      >
        <div className="tw-relative">
          {errorCode && (
            <NoData
              type="error"
              title={i18n.t("error.fetch")}
              description={i18n.t(errorCode)}
            />
          )}
          {isLoading && <Loading />}
          {isSuccess && !isLoading && (
            <div>
              <div className="tw-flex tw-justify-between tw-py-4 max-md:tw-hidden">
                <div className="tw-flex tw-gap-2">
                  <SelectVersion
                    selectedVersion={selectedVersion}
                    availableVersions={ledgerVersions}
                    onChange={setSelectedVersion}
                  />
                  <Input
                    className="tw-h-12 tw-w-52 tw-rounded-md max-md:tw-w-full"
                    placeholder={i18n.t("label.search")}
                    prefix={<SearchIcon />}
                    type="search"
                    value={searchValue}
                    onChange={(event) => {
                      setSearchValue(event.target.value);
                    }}
                  />
                  <ShareView
                    orgNumber={currentCompany.orgNumber}
                    selectedVersion={selectedVersion}
                  />
                  <div className="tw-flex tw-gap-2">
                    <Menu className="max-md:tw-w-full">
                      <Menu.Button
                        type="button"
                        className="tw-flex tw-items-center tw-rounded-md tw-p-5 max-md:tw-w-full"
                        isDropdown={false}
                        isLoading={isDownloadLoading}
                        onClick={() => downloadShareholders()}
                      >
                        <FilePdf className="tw-h-6 tw-w-6" />
                      </Menu.Button>
                    </Menu>

                    {/* excel */}
                    <Menu className="max-md:tw-w-full">
                      <Menu.Button
                        className={clsxm(
                          "tw-flex tw-items-center tw-rounded-md tw-p-5 max-md:tw-w-full",
                          {
                            "tw-cursor-not-allowed tw-opacity-50":
                              isSortedHoldersEmpty,
                          }
                        )}
                        disabled={isSortedHoldersEmpty}
                        isDropdown={false}
                        type="button"
                        onClick={() => {
                          if (isSortedHoldersEmpty) {
                            return;
                          }

                          const data = generateShareholderData(
                            mappedShareHolders,
                            displayDiluted,
                            {
                              votes: totalVotes,
                              shares: totalShares,
                              sharesByType: totalSharesByType,
                              dilutedShares: dilutedTotals.totalShares,
                              dilutedVotes: dilutedTotals.totalVotes,
                            },
                            i18n
                          );

                          makeAndDownloadExcel(data, {
                            currentCompany,
                            title: i18n.t("label.shareholders"),
                            version: versionLabel,
                            downloaded: i18n.t("label.downloaded", {
                              date: new Date().toLocaleDateString(getLocale()),
                            }),
                          });
                        }}
                      >
                        <ExcelIcon className="tw-h-6 tw-w-6" />
                      </Menu.Button>
                    </Menu>
                  </div>
                </div>
                {optionsPrograms.length > 0 && (
                  <Toggle
                    label={i18n.t("label.diluted")}
                    className="tw-ml-2 print:tw-hidden"
                    isActive={displayDiluted}
                    onClick={() => toggleDilutedView()}
                  />
                )}
              </div>
              {displayDiluted && (
                <OptionsProgramFilter
                  optionsPrograms={optionsPrograms}
                  selectedOptionsPrograms={selectedOptionsProgramIds}
                  setSelectedOptionsPrograms={setSelectedOptionsProgramsIds}
                  className="tw-py-2 print:tw-hidden max-md:tw-hidden"
                />
              )}
              <div>
                <div
                  id="mobile-view"
                  className="tw-flex tw-flex-col tw-gap-2 tw-pb-10 md:tw-hidden"
                >
                  <div className="tw-flex tw-flex-col tw-gap-4">
                    <Input
                      placeholder={i18n.t("label.search")}
                      prefix={<SearchIcon />}
                      type="search"
                      value={searchValue}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                      className="tw-h-12 tw-rounded-md"
                    />
                    {optionsPrograms.length > 0 && (
                      <Toggle
                        label={i18n.t("label.diluted")}
                        className="tw-ml-2 print:tw-hidden"
                        isActive={displayDiluted}
                        onClick={() => toggleDilutedView()}
                      />
                    )}
                  </div>
                  {displayDiluted && (
                    <OptionsProgramFilter
                      optionsPrograms={optionsPrograms}
                      selectedOptionsPrograms={selectedOptionsProgramIds}
                      setSelectedOptionsPrograms={setSelectedOptionsProgramsIds}
                      className="tw-py-2 print:tw-hidden"
                    />
                  )}
                </div>
                {hasSplit && (
                  <p className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-border tw-p-6 tw-text-gray-500">
                    <InfoIcon className="tw-h-6 tw-w-6 tw-shrink-0" />
                    <div className="tw-text-bottom tw-text-sm">
                      {i18n.t("shareholders.splitCalculation")}
                    </div>
                  </p>
                )}
                <ShareholdersTable
                  displayDiluted={displayDiluted}
                  dilutedTotals={dilutedTotals}
                  searchValue={searchValue}
                  totalShares={totalShares}
                  totalVotes={totalVotes}
                  shareholders={mappedShareHolders}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </div>
            </div>
          )}
          <BottomBar
            currentCompany={currentCompany}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            ledgerVersions={ledgerVersions}
            enableViewShare
            enableExcelDownload={!isSortedHoldersEmpty}
            isDownloadLoading={isDownloadLoading}
            downloadPdf={downloadShareholders}
            downloadExcel={() => {
              const data = generateShareholderData(
                mappedShareHolders ?? [],
                displayDiluted,
                {
                  votes: totalVotes ?? 0,
                  shares: totalShares ?? 0,
                  sharesByType: totalSharesByType ?? {},
                  dilutedShares: dilutedTotals.totalShares,
                  dilutedVotes: dilutedTotals.totalVotes,
                },
                i18n
              );

              makeAndDownloadExcel(data, {
                currentCompany,
                title: i18n.t("label.shareholders"),
                version: versionLabel,
                downloaded: i18n.t("label.downloaded", {
                  date: new Date().toLocaleDateString(getLocale()),
                }),
              });
            }}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export type { SortBy };
export default Shareholders;
