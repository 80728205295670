import { TxRejectedError } from "postchain-client";
import { z } from "zod";

import type { Ti18nKey } from "../i18n";
import type { NonEmptyArray } from "../types";
import * as monitoring from "../utils/monitoring";
import { FORBIDDEN, INTERNAL_SERVER_ERROR, UNAUTHORIZED } from "./rest/utils";

type Message = { code: Ti18nKey; text?: string };

type RequestErrorElement = { field?: string; message: Message };

type IRequestError = {
  readonly status: Response["status"];
  readonly errors: NonEmptyArray<RequestErrorElement>;
};

class RequestError extends Error implements IRequestError {
  errors: IRequestError["errors"];

  status: Response["status"];

  constructor(error: IRequestError) {
    super();
    this.name = "RequestError";
    this.message = "Request failed";
    this.errors = error.errors;
    this.status = error.status;
  }
}

type ErrorType = "general" | "unauthenticated" | "unauthorized";

const Errors: { [key in ErrorType]: IRequestError } = {
  unauthenticated: {
    errors: [{ message: { code: "error.verification.unauthenticated" } }],
    status: UNAUTHORIZED,
  },

  unauthorized: {
    errors: [{ message: { code: "error.verification.unauthorized" } }],
    status: FORBIDDEN,
  },

  general: {
    errors: [{ message: { code: "error.general" } }],
    status: INTERNAL_SERVER_ERROR,
  },
};

const ShortReasonSchema = z.array(
  z.object({
    message: z.object({
      code: z.string(),
    }),
  })
);

const shortReasonPreprocessor = z.preprocess((input) => {
  if (typeof input === "string") {
    try {
      return JSON.parse(input);
    } catch (error) {
      throw new Error("Invalid JSON format.");
    }
  }
  return input;
}, ShortReasonSchema);

const convertErrorToTi18nKey = (
  error: TxRejectedError | IRequestError
): Ti18nKey[] => {
  if (error instanceof TxRejectedError) {
    const shortReason = shortReasonPreprocessor.safeParse(error.shortReason);
    return (
      [
        shortReason.data?.[0]?.message.code as Ti18nKey | undefined,
        "error.general",
      ] as const
    ).filter((x) => x !== undefined);
  }
  if (error.errors) {
    return [error.errors[0].message.code, "error.general"];
  }
  monitoring.captureException(Error("Unexpected error response"), {
    contexts: { error },
  });
  return ["error.general"];
};

export { convertErrorToTi18nKey, Errors, RequestError };
export type { IRequestError, RequestErrorElement };
